import React from 'react';

import { AuthProvider } from './FirebaseAuth';
import { ToastProvider } from './Toast';
import { I18Provider } from './i18';
import { EventProvider } from './EventContext';
import { FirebaseProvider } from './Firebase';

const AppProvider: React.FC = ({ children, ...rest }) => (
  <FirebaseProvider>
    <I18Provider>
      <AuthProvider>
        <ToastProvider>
          <EventProvider {...rest}>
            {children}
          </EventProvider>
        </ToastProvider>
      </AuthProvider>
    </I18Provider>
  </FirebaseProvider>

);

export default AppProvider;
