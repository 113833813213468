import React, { useCallback, useRef, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as firebase from "firebase/app";
import { useAuth } from '../../hooks/FirebaseAuth';
import { useToast } from '../../hooks/Toast';
import { AnimationContainer } from './styles';
import OpenMasterPage from '../../masterpages/OpenMasterPage';
import { useEvent } from '../../hooks/EventContext';
import { useForm } from 'react-hook-form';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = (props: any) => {
  const { register, setValue, handleSubmit, formState: { errors } } = useForm<SignInFormData>();
  const [sending, setSending] = useState(false)
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail é obrigatório')
          .email('Digite um e-mail válido'),
        password: Yup.string().required('Senha é obrigatória')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // await signIn({
      //   email: `${props.params.event}:${data.email}`,
      //   password: data.password
      // });

      setSending(true);

      await signIn({
        email: data.email,
        password: data.password
      });

      history.push(`/dashboard`);

      setSending(false);

    } catch (error) {
      setSending(false);

      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }
  });

  return (
    <OpenMasterPage {...props}>
      <AnimationContainer>
        <form onSubmit={onSubmit}>
          <h3 style={{color: 'white'}}>AC EVENTS PARTNER</h3>
          <input type="text" {...register("email", { required: true })} placeholder="E-mail" />
          <input type="password" {...register("password", { required: true })} placeholder="Senha" /><br />
          <button type="submit" disabled={sending}>{sending ? 'Validando...' : 'Entrar'}</button>
        </form>
      </AnimationContainer>
    </OpenMasterPage>
  );
};

export default SignIn;
