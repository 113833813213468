import React from 'react';
import { Container } from './styles';

export interface MasterProps {
  noContent: boolean;
}

const OpenMasterPage: React.FC<MasterProps> = (props) => {

  return (
    <Container>
      <main>
        {!props.noContent ? (<aside className="col-md-4">{props.children}</aside>) : ''}
      </main>
    </Container>
  );
};

export default OpenMasterPage;
