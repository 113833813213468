import React from 'react';
import { useAuth } from '../../hooks/FirebaseAuth';

import { Container, Title, ExpandIcon } from './styles';

const ServerName: React.FC = () => {

  const { user } = useAuth();

  return (
    <Container>
      <Title>AC Events: {user.event?.key?.toUpperCase()}</Title>

      <ExpandIcon />
    </Container>
  );
};

export default ServerName;
