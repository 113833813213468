import 'firebase/firestore';
import 'firebase/auth';

import { Button, Modal } from 'antd';
import ChannelMessage, { Mention } from '../ChannelMessage';
import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap';
import { Container, Input, InputIcon, InputWrapper, Messages } from './styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import api from '../../services/api';
import firebase from 'firebase';
import moment from 'moment';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useForm } from 'react-hook-form';
import { useInterval } from 'beautiful-react-hooks';
import { useToast } from '../../hooks/Toast';
import { v4 } from 'uuid';

const ChannelData: React.FC = (props: any) => {
  const messagesRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [active, setActive] = useState<number>(1);
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, reset } = useForm<any>({});
  const { addToast } = useToast();
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState<any>({});
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 10,
    skip: 0,
  });
  const [mailing, setMailing] = useState<any[]>([]);
  const [resources, setResources] = useState<any>({});
  const [visibleImport, setVisibleImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const { user, signOut } = useAuth();

  useEffect(() => {
    const div = messagesRef.current;

    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  }, [messagesRef]);

  async function upload(inputFile: any): Promise<string> {
    if (inputFile) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${user.id}/importation/${v4()}`)
        .put(inputFile);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  useEffect(() => {
    user.onOpenModal = () => {
      setVisibleImport(true);
    };
  }, [user.onOpenModal]);

  const onSelectedFile = (file: React.ChangeEvent<HTMLInputElement>) => {
    if (file.currentTarget.files !== null)
      setSelectedFile(file.currentTarget.files[0]);
  };

  const loadMailing = useCallback(() => {
    if (user.event?.id) {
      api
        .post('/admin/mailing/list', {
          filter: { eventId: user.event?.id, ...filters },
          pagination,
        })
        .then((r) => {
          setMailing(r.data.result);
          setCount(r.data.count);
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          setLoading(false);
          if (e?.code?.toString() == "401") {
            signOut()
          }
        });
    }
  }, [filters, pagination, props?.event?.id]);

  useEffect(() => {
    loadMailing()
  }, [loadMailing])

  useInterval(() => {
    if (!loading) {
      setLoading(true);
      loadMailing()
    }

  }, 5000);

  const importFile = async () => {
    setLoadingSubmit(true);

    if (!selectedFile) {
      addToast({
        type: 'error',
        title: 'Selecione um arquivo',
        description: 'O arquivo é obrigatório.',
      });
      return;
    }

    const link = await upload(selectedFile);

    try {
      const result = await api.post('/admin/mailing/import', {
        eventId: user.event.id,
        eventKey: user.event.key,
        link,
      });

      addToast({
        type: 'success',
        title: 'Importação realizada com sucesso',
        description: 'Sua informação foi enviada com sucesso!',
      });

      setVisibleImport(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }

    setLoadingSubmit(false);
    // setVisibleImport(false);
  };

  return (
    <Container>
      <Modal
        visible={visibleImport}
        title="Importar CSV"
        onCancel={() => {
          setVisibleImport(false);
        }}
        footer={[
          <Button key="back" onClick={() => {
            setVisibleImport(false);
          }}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            typeof="button"
            loading={loadingSubmit}
            onClick={importFile}
          >
            Importar
          </Button>,
        ]}
      >
        <Form.Group as={Col} md={12}>
          <Form.Label htmlFor="import">Importar Lista</Form.Label>
          <div className="custom-file">
            <Form.File
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                console.log('file on change', e);
                onSelectedFile(e);
              }}
              label="Selecionar Arquivo"
              name="import"
            />
          </div>
        </Form.Group>
      </Modal>
      <InputWrapper>
        <Input type="text" placeholder="Buscar por e-mail" onChange={(e) => {
          setFilters({ ...(e.target.value ? { email: e.target.value } : {}) })
        }} />
      </InputWrapper>
      <Messages ref={messagesRef}>
        {!!count && <ChannelMessage
          key="count"
          author={"TOTAL DE EMAILS"}
          date={""}
          content={count}
        />}
        {mailing.map((n) => (
          <ChannelMessage
            key={n.email}
            author={n.name}
            date={moment(n.createdAt).format()}
            content={n.email}
          />
        ))}

      </Messages>
    </Container>
  );
};

export default ChannelData;
