// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyBUKuraIRTi0msI7yVKmPHPDaw0QCGmIeo",
  authDomain: "antonicelli-official.firebaseapp.com",
  databaseURL: "https://antonicelli-official.firebaseio.com",
  projectId: "antonicelli-official",
  storageBucket: "antonicelli-official.appspot.com",
  messagingSenderId: "384201856923",
  appId: "1:384201856923:web:9d9faf888d730da88cf33e",
  measurementId: "G-S5K1NS3GBD"
};

export default config;
