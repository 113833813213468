import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import config from "../config";
import { useHistory, useLocation } from 'react-router-dom';

interface User {
  onOpenModal: () => void;
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  event: {
    id: string,
    key: string
  }
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<firebase.auth.UserCredential>;
  signOut(): void;
  updateUser(user: User): void;
}

const Auth = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  let [user, setUser] = useState<any>();
  let history = useHistory();
  //let location = useLocation();

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
  //     if (firebaseUser) {
  //       if (!user || user.refreshToken != firebaseUser.refreshToken) {

  //         setUser(firebaseUser);
  //         await updateToken(firebaseUser);
  //         if (location.pathname.indexOf("/dashboard") == -1) {
  //           history.replace("dashboard");
  //         }
  //       }
  //     }
  //   });

  //   return () => {
  //     unsubscribe();
  //   }
  // }, [user]);

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Storage:token');
    const user = localStorage.getItem('@Storage:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
        user: JSON.parse(user),
      };
    }
    return {} as AuthState;
  });

  async function updateToken(user: firebase.User | null) {
    const tokenResult = await user?.getIdTokenResult();
    const token = tokenResult?.token || "";

    api.defaults.headers.authorization = `Bearer ${token}`;
    const profile = await api.get("/profile");
    const profileData = profile.data;

    const userConverted: User = {
      avatar_url: user?.photoURL || "",
      name: user?.displayName || "",
      email: user?.email || "",
      id: user?.uid || "",
      ...profileData,
      onOpenModal: () => {}
    }

    localStorage.setItem('@Storage:token', token);
    localStorage.setItem('@Storage:user', JSON.stringify(userConverted));

    setData({ token, user: userConverted });
  }

  const signIn = useCallback(async ({ email, password }) => {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    await updateToken(response.user);
    return response;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Storage:token');
    localStorage.removeItem('@Storage:user');
    firebase.auth().signOut();
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@Storage:user', JSON.stringify(user));

      let currentUser = firebase.auth().currentUser;
      if (currentUser) {
        let updatedUser = { ...currentUser, ...user };
        firebase.auth().updateCurrentUser(updatedUser);
      }

      setData({
        token: data.token,
        user
      });
    },
    [setData, data.token],
  );

  return (
    <Auth.Provider value={{ user: data.user, signIn, signOut, updateUser }}>
      {children}
    </Auth.Provider >
  );
};

function useAuth(): AuthContextData {
  const context = useContext(Auth);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
