import React from 'react';
import { useAuth } from '../../hooks/FirebaseAuth';

import { Container, HashtagIcon, InviteIcon, SettingsIcon } from './styles';

export interface Props {
  channelName: string;
  selected?: boolean;
}

const ChannelButton: React.FC<Props> = ({ channelName, selected }) => {
  const { user } = useAuth();
  return (
    <Container className={selected ? 'active' : ''}>
      <div>
        <span>{channelName}</span>
      </div>

      <div>
        <InviteIcon onClick={() => {
          user.onOpenModal();
        }} />
        {/* <SettingsIcon /> */}
      </div>
    </Container>
  );
};

export default ChannelButton;
