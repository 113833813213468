import React from 'react';
import { useAuth } from '../../hooks/FirebaseAuth';

import {
  Container,
  Profile,
  Avatar,
  UserData,
  Icons,
  MicIcon,
  HeadphoneIcon,
  LogOutIcon,
} from './styles';

const UserInfo: React.FC = () => {
  const { user, signOut } = useAuth();


  return (
    <Container>
      <Profile>
        <Avatar />
        <UserData>
          <strong>{user.email}</strong>          
        </UserData>
      </Profile>

      <Icons>
        <LogOutIcon onClick={() => {
          signOut();
        }} />
      </Icons>
    </Container>
  );
};

export default UserInfo;
