import React from 'react';

import ChannelButton from '../ChannelButton';

import { Container, Category, AddCategoryIcon } from './styles';

const ChannelList: React.FC = () => {
  return (
    <Container>
      <ChannelButton channelName="Mailing" />
    </Container>
  );
};

export default ChannelList;
