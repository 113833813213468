import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import config from "../config";

firebase.initializeApp(config);
const Firebase = createContext<any>(firebase);

const FirebaseProvider: React.FC = ({ children }) => {

  return (
    <Firebase.Provider value={firebase}>
      {children}
    </Firebase.Provider >
  );
};

function useFirebase(): any {
  const context = useContext(Firebase);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { FirebaseProvider, useFirebase };
