

import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { useHistory } from 'react-router-dom';
import { useToast } from './Toast';

const EventContext = createContext<EventData>({} as EventData);

export interface EventData {
  eventKey: string;
  event: any;
  channel: any;
  channels: any[];
  speakers: any[];
  chat: any;

  loadEvent(eventKey: string): void;
  loadInfo(eventKey: string, channelKey?: string): void;
}

const EventProvider: React.FC = (props: any) => {
  const history = useHistory();

  const [eventKey, setEventKey] = useState("");
  const [channelKey, setChannelKey] = useState("");
  const [event, setEvent] = useState<any>(null);
  const [channel, setChannel] = useState<any>(null);
  const [channels, setChannels] = useState<any[]>([]);
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [chat, setChat] = useState<any>(null);
  const { addToast } = useToast();

  const loadEvent = async (key: string) => {
    if (key != eventKey) {
      setEventKey(key);
      console.log(eventKey);

      try {
        const result = await api.get("/event/public-info", { params: { "key": key } });
        if (result.data.data) {
          setEvent(result.data.data);
          console.log(result.data.data);
        }
        else {
          addToast({
            type: 'error',
            title: 'Link não encontrado',
            description: 'Tente acessar novamente mais targe',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Link não encontrado',
          description: 'Tente acessar novamente mais targe',
        });
      }

    }
  }

  const loadInfo = async (key: string, cKey: string = "") => {
    if (eventKey != key || channelKey != cKey || !channel) {
      setEventKey(key);
      setChannelKey(cKey);

      try {
        const result = await api.get(`/event/info?eventKey=${key}&channelKey=${cKey}`);
        if (result.data.data) {
          setEvent(result.data.data.event);
          setChannel(result.data.data.channel);
          setChannels(result.data.data.channels);
          setSpeakers(result.data.data.speakers);
          setChat(result.data.data.chat);
          console.log(result.data.data.event);
        }
        else {
          history.push(`/${key}/signin/${cKey}`);
        }
      } catch (error) {
        history.push(`/${key}/signin/${cKey}`);
      }


    }
  }

  return (
    <>
      <EventContext.Provider value={{ eventKey, event, channel, loadEvent, loadInfo, channels, speakers, chat }}>
        {props.children}
      </EventContext.Provider>      
    </>
  );
};

function useEvent(): EventData {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { EventProvider, useEvent };
